import * as Sentry from '@sentry/astro'

Sentry.init({
  dsn: 'https://8d68551a0a834e2b4ef7ca4a00e5b8ed@o4506913974910976.ingest.us.sentry.io/4506914178400256',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false
    })
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0
})
